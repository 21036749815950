import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom"
import { CampaignPPCRequestAction,CampaignDetail } from '../../redux/Slices/CampaignSlice';
import { CampaignViewModal } from '../../components/Modal/CampaignViewModal';
import { Col, Container, Row } from 'react-bootstrap';
import '../../../src/pages/PageNotFound/PageNotFound.scss';
// import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { Helmet } from 'react-helmet';
//import {HelmetProvider,Helmet } from 'react-helmet-async';

const CampaignPPCRequest = () => {
    const [campaignStatus, setCampaignStatus] = useState(4)
    const [CampaigndetailModalShow, setCampaigndetailModalShow] = useState(false);
    const [campaignDetailData, setCampaignDetailData] = useState();
    const location = useLocation();
    const params = new URLSearchParams(location.search)
    let campaignShortLink = params.get('link');
    let campaignOgUrl = params.get('url');

    // useEffect(() => {
    //     window._fmOpt = {
    //         success: function (result) {
    //         }
    //     };

    //     const fm = document.createElement('script');
    //     fm.type = 'text/javascript';
    //     fm.async = true;
    //     fm.src = 'https://cdn.jsdelivr.net/npm/@trustdevicejs/trustdevice-js@1.0.3/dist/fm.min.js?t=' + (new Date().getTime() / 3600000).toFixed(0);
    //     const s = document.getElementsByTagName('script')[0];
    //     s.parentNode.insertBefore(fm, s);
    //     return () => {
    //         s.parentNode.removeChild(fm);
    //     };
    // }, []);

    // Fetch local system IP address
    function getLocalIPAddress() {
        return new Promise((resolve, reject) => {
            const RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
            
            if (!RTCPeerConnection) {
                console.warn("WebRTC not supported on this browser");
                resolve("WebRTC not supported");
                return;
            }
    
            const connection = new RTCPeerConnection();
            connection.createDataChannel('');
            connection.onicecandidate = (event) => {
                if (event.candidate) {
                    const address = event.candidate.address || event.candidate.ip;
                    resolve(address);
                } else {
                    reject('Local IP address not found');
                }
                connection.close();
            };
    
            connection.createOffer()
                .then(offer => connection.setLocalDescription(offer))
                .catch(() => reject('Error creating offer'));
        });
    }
    
    // function getLocalIPAddress() {
    //     return new Promise((resolve, reject) => {
    //         const RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
    //         if (!RTCPeerConnection) {
    //             reject('WebRTC not supported');
    //         }

    //         const connection = new RTCPeerConnection();
    //         connection.createDataChannel('');
    //         connection.onicecandidate = (event) => {
    //             if (event.candidate) {
    //                 const address = event.candidate.address || event.candidate.ip;
    //                 resolve(address);
    //             } else {
    //                 reject('Local IP address not found');
    //             }
    //             connection.close();
    //         };
    //         connection.createOffer((offer) => {
    //             connection.setLocalDescription(offer);
    //         }, () => {
    //             reject('Error creating offer');
    //         });
    //     });
    // }

    // useEffect(() => {
    //     fetch('https://api.ipify.org?format=json')
    //         .then(response => response.json())
    //         .then(async (data) => {
    //             let fingerprintip = {
    //                 public_ip: data.ip
    //             }

    //             // let fingerprintip = {
    //             //     short_link_id: campaignShortLink,
    //             //        fingerprint: data.ip,
    //             //     public_ip: data.ip,
    //             //     font: window.deviceDetail.font,
    //             //     hardwareConcurrency: window.deviceDetail.hardwareConcurrency,
    //             //     height: window.deviceDetail.height,
    //             //     language: window.deviceDetail.language,
    //             //     maxTouchPoints: window.deviceDetail.maxTouchPoints,
    //             //     mimeTypes: window.deviceDetail.mimeTypes,
    //             //     pixelDepth: window.deviceDetail.pixelDepth,
    //             //     platform: window.deviceDetail.platform,
    //             //     plugins: window.deviceDetail.plugins,
    //             //     support: window.deviceDetail.support,
    //             //     timezone: window.deviceDetail.timezone,
    //             //     touchSupport: window.deviceDetail.touchSupport,
    //             //     webdriver: window.deviceDetail.webdriver,
    //             //     width: window.deviceDetail.width,
    //             //     devicePixelRatio: window.devicePixelRatio,
    //             //     deviceRiskLabel: window.deviceRiskLabel,
    //             //     getCanvas: window.getCanvas(),
    //             //     md5: window.md5$1(),
    //             //     memory: window.performance.memory,
    //             //     screen: {
    //             //         availHeight: window.availHeight,
    //             //         availLeft: window.availLeft,
    //             //         availTop: window.availTop,
    //             //         availWidth: window.availWidth,
    //             //         colorDepth: window.colorDepth,
    //             //         height: window.height,
    //             //         isExtended: window.isExtended,
    //             //         width: window.width,
    //             //         pixelDepth: window.pixelDepth
    //             //     }
    //             // }
    //             await getLocalIPAddress()
    //                 .then(ip => {
    //                     console.log("ip", ip)
    //                     fingerprintip["local_ip"] = ip
    //                 })
    //                 .catch(error => {
    //                     console.error('Error fetching local IP address:', error);
    //                 });
    //             await CampaignPPCRequestAction({ short_link_id: campaignShortLink, fingerprint: data.ip, local_ip: fingerprintip.local_ip }, (response) => {
    //                 if (response?.status === true) {
    //                     window.location.href = response?.data?.target_link
    //                 } else {
    //                     if (response?.data?.campaign_status) {
    //                         setCampaignStatus(response?.data?.campaign_status)
    //                     }
    //                 }
    //             })()
    //         })
    //         .catch(error => {
    //             console.error('Error fetching public IP address:', error);
    //         });
    // }, [])

    // function campaignredirect(){
    //     fetch('https://api.ipify.org?format=json')
    //         .then(response => response.json())
    //         .then(async (data) => {
    //             let fingerprintip = {
    //                 public_ip: data.ip
    //             }

    //             // let fingerprintip = {
    //             //     short_link_id: campaignShortLink,
    //             //        fingerprint: data.ip,
    //             //     public_ip: data.ip,
    //             //     font: window.deviceDetail.font,
    //             //     hardwareConcurrency: window.deviceDetail.hardwareConcurrency,
    //             //     height: window.deviceDetail.height,
    //             //     language: window.deviceDetail.language,
    //             //     maxTouchPoints: window.deviceDetail.maxTouchPoints,
    //             //     mimeTypes: window.deviceDetail.mimeTypes,
    //             //     pixelDepth: window.deviceDetail.pixelDepth,
    //             //     platform: window.deviceDetail.platform,
    //             //     plugins: window.deviceDetail.plugins,
    //             //     support: window.deviceDetail.support,
    //             //     timezone: window.deviceDetail.timezone,
    //             //     touchSupport: window.deviceDetail.touchSupport,
    //             //     webdriver: window.deviceDetail.webdriver,
    //             //     width: window.deviceDetail.width,
    //             //     devicePixelRatio: window.devicePixelRatio,
    //             //     deviceRiskLabel: window.deviceRiskLabel,
    //             //     getCanvas: window.getCanvas(),
    //             //     md5: window.md5$1(),
    //             //     memory: window.performance.memory,
    //             //     screen: {
    //             //         availHeight: window.availHeight,
    //             //         availLeft: window.availLeft,
    //             //         availTop: window.availTop,
    //             //         availWidth: window.availWidth,
    //             //         colorDepth: window.colorDepth,
    //             //         height: window.height,
    //             //         isExtended: window.isExtended,
    //             //         width: window.width,
    //             //         pixelDepth: window.pixelDepth
    //             //     }
    //             // }
    //             await getLocalIPAddress()
    //                 .then(ip => {
    //                     console.log("ip", ip)
    //                     fingerprintip["local_ip"] = ip
    //                 })
    //                 .catch(error => {
    //                     console.error('Error fetching local IP address:', error);
    //                 });
    //             await CampaignPPCRequestAction({ short_link_id: campaignShortLink, fingerprint: data.ip, local_ip: fingerprintip.local_ip }, (response) => {
    //                 if (response?.status === true) {
    //                     window.location.href = response?.data?.target_link
    //                 } else {
    //                     if (response?.data?.campaign_status) {
    //                         setCampaignStatus(response?.data?.campaign_status)
    //                     }
    //                 }
    //             })()
    //         })
    //         .catch(error => {
    //             console.error('Error fetching public IP address:', error);
    //         });
    // }
    async function campaignredirect(){
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            
            let fingerprintip = { public_ip: data.ip };
            
            await getLocalIPAddress()
                .then(ip => {
                    fingerprintip["local_ip"] = ip;
                })
                .catch(error => {
                    console.error('Error fetching local IP address:', error);
                });
    
                await CampaignPPCRequestAction({ short_link_id: campaignShortLink, fingerprint: data.ip, local_ip: fingerprintip.local_ip }, (response) => {
                    console.log("callingdata",data.ip,fingerprintip.local_ip)
                        if (response?.status === true) {
                            window.location.href = response?.data?.target_link
                        } else {
                            if (response?.data?.campaign_status) {
                                setCampaignStatus(response?.data?.campaign_status)
                            }
                        }
                    })()
        } catch (error) {
            console.error('Error fetching public IP address or processing the campaign redirect:', error);
        }
    }
    
    useEffect(() => {
    CampaignDetail({ short_link_id: campaignShortLink }, (response) => {
        if (response?.status === true) {
            setCampaignDetailData(response?.data)
            setCampaigndetailModalShow(true)
        } else {
        }
    })()
    }, [])
    
    return (
        <>
            <Helmet>
            {/* <script src="https://polyfill.io/v3/polyfill.min.js?features=fetch%2CPromise%2Cdefault"></script> */}
                {/* <!-- HTML Meta Tags --> */}
                <title>Glidescale || Welcome to Glidescale</title>
                <meta name="description" content="Glidescale || Welcome to Glidescale"/>

               {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content="https://www.glidescale.com/campaign-request"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Glidescale || Welcome to Glidescale"/>
                <meta property="og:description" content="Glidescale || Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale"/>
                <meta property="og:image" content="https://www.w3schools.com/images/w3schools_logo_436_2.png"/>

                {/*<!-- Twitter Meta Tags -->*/}
                <meta property="twitter:domain" content="glidescale.com"/>
                <meta property="twitter:url" content="https://www.glidescale.com/campaign-request"/>
                <meta name="twitter:title" content="W3Schools Online Web Tutorials"/>
                <meta name="twitter:description" content="Glidescale || Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale  Welcome to Glidescale"/>
                <meta name="twitter:image" content="https://www.w3schools.com/images/w3schools_logo_436_2.png"/>

            </Helmet>
            <section className="page-not-found ppc_redirect">
                <Container>
                    <Row>
                        <Col md={12} className='text-center'>
                            {
                                campaignStatus == 1 ?
                                    <>
                                        <h4> Temporarily closed </h4>
                                        <p> Please contact the support team immediately as we are currently experiencing an unresolved issue with redirecting this URL. </p>
                                    </>
                                    :
                                    campaignStatus == 2 ?
                                        <>
                                            <h4> Campaign deactivated </h4>
                                            <p> Please contact the support team immediately as we are currently experiencing an unresolved issue with redirecting this URL. </p>
                                        </>
                                        :
                                        campaignStatus == 3 ?
                                            <>
                                                <h4> Invalid link </h4>
                                                <p> Please contact the support team immediately as we are currently experiencing an unresolved issue with redirecting this URL. </p>
                                            </>
                                            :
                                            <>
                                                <h4>Please Wait...</h4>
                                                <p>Do not refresh or close this page until we redirect you.</p>
                                            </>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
            <CampaignViewModal
                show={CampaigndetailModalShow}
                modalClass="campaign_modal_view"
                onHide={() => setCampaigndetailModalShow(false)} // Close modal, so it should be set to false
                title="Confirmation Alert!" // Changed to title
                body={
                    <>
                        <div className='campaign_view_details'>
                            <div className='campaign_img'>
                                <img src={campaignDetailData?.campaign_image} alt="" />
                            </div>
                            <h1>{campaignDetailData?.campaign_title} </h1>
                        <h5>{campaignDetailData?.campaign_description?.length >= 170 ? `${campaignDetailData?.campaign_description}...` : campaignDetailData?.campaign_description}</h5> 
                        <div className='new_Campaigns_input tow_btn_row'>
                        <button className='btn_blue' onClick={() => window.location.href = '/login'}>Cancel</button> {/* Button for submit */}
                        <button className='btn_blue proceed_btn' disabled={false} onClick={() => {
                            console.log('Button clicked');
                             campaignredirect()}}>Proceed</button> 
                        {/* <button
                        className="btn_blue proceed_btn"
                        onClick={(e) => {
                            e.stopPropagation(); // Ensure the click event is not being stopped by another handler
                            e.preventDefault();  // Prevent any default browser behavior
                            campaignredirect();
                        }}
                        >
                        Proceed
                        </button> */}
                        </div>
                        </div>
                    </>
                }
                //modalClass="delete_Campaigns_modal" // Class for modal
            />
        </>
    )
}

export default CampaignPPCRequest